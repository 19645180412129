import React from 'react'
import '../Styles/footer.css'
import { FaInstagram, FaLinkedin, FaFacebook } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-top'>
        <div className='footer-column'>
          <h4>INDUSTRIES</h4>
          <ul>
            <li>Banking & Financial Services</li>
            <li>Energy & Commodities</li>
            <li>Healthcare</li>
            <li>Public Sector</li>
            <li>Retail</li>
            <li>Telecommunications Media & Technology</li>
            <li>Transportation & Mobility</li>
          </ul>
        </div>
        <div className='footer-column'>
          <h4>SERVICES</h4>
          <ul>
            <li>Business Transformation</li>
            <li>Digital Transformation</li>
            <li>Learning Transformation</li>
            <li>Technology Training Programs</li>
          </ul>
        </div>
        <div className='footer-column'>
          <h4>QUICK LINKS</h4>
          <ul>
            <li>Privacy Policy</li>
            <li>Refund Policy</li>
            <li>Terms & Conditions</li>
            <li>Blogs</li>
          </ul>
        </div>
        <div className='footer-column'>
          <h4>INITIATIVES</h4>
          <ul>
            <li>Panther Diaries</li>
          </ul>
        </div>
        <div className='footer-social'>
          <a href='#'>About Us</a>
          <a href='#'>Career</a>
          <a href='#'>Contact Us</a>
          <div className='social-icons'>
            <a href='#'>
              <FaFacebook />
            </a>
            <a href='https://www.linkedin.com/in/aiglobalmuseum-madan-5a598233b/overlay/about-this-profile/'>
              <FaLinkedin />
            </a>
            <a href='https://www.instagram.com/aiglobalmuseum/#'>
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
