import React, { useEffect } from 'react'
import Header from './Header' // Adjust the path if necessary
import Footer from './Footer' // Adjust the path if necessary
import { motion } from 'framer-motion'

import HistoryImage from '../Assests/icons Ai/311b6a216faf56af11c5f3f4117ae88f 1.svg'
import HistoryImage2 from '../Assests/icons Ai/HistoryImage2.svg'
import HistoryVideo from '../Assests/AI videos/freepik__a-virtual-museum-scene-featuring-a-central-statue-__44616.mp4'

const History = () => {
  return (
    <div
      style={{
        minHeight: '100vh',
        // backgroundColor: '#0a0a1e',
        background: 'linear-gradient(180deg, #06071B 50%, #22144A 100%)',
        color: 'white',
        fontFamily: "'Arial', sans-serif",
      }}
    >
      <Header />

      {/* Hero Section */}
      <motion.section
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        style={{
          position: 'relative',
          height: '100vh',
          textAlign: 'center',
          overflow: 'hidden',
        }}
      >
        {/* Background Image */}
        {/* <img
          src={HistoryImage}
          alt='AI Smart Home'
          style={{
            position: 'absolute',
            top: '0',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        /> */}
        <video
          className='object-fit-cover w-100 h-100'
          autoPlay
          muted
          loop
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 1,
          }}
        >
          <source src={HistoryVideo} type='video/mp4' />
          Your browser does not support the video tag.
        </video>

        {/* Text Overlay */}
        <div
          style={{
            position: 'absolute',
            top: '50%', // Vertically center
            left: '50%', // Horizontally center
            transform: 'translate(-50%, -50%)', // Adjust position
            color: 'white',
            textAlign: 'center',
            zIndex: 2,
          }}
        >
          {/* <h1 style={{ fontSize: '3rem', fontWeight: 'bold' }}>
            Welcome to AI Museum
          </h1>
          <p
            style={{ fontSize: '1.5rem', maxWidth: '600px', margin: '0 auto' }}
          >
            Discover the incredible journey of artificial intelligence, from its
            origins to its impact on the future.
          </p> */}
        </div>

        {/* Semi-Transparent Overlay */}
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))',
            zIndex: 2,
          }}
        ></div>

        {/* Text Content */}
        <div
          style={{
            position: 'absolute',
            top: '50%', // Vertically center
            left: '50%', // Horizontally center
            transform: 'translate(-50%, -50%)', // Adjust position
            color: 'white',
            textAlign: 'center',
            zIndex: 3,
          }}
        >
          <h1 style={{ fontWeight: '900', fontSize: '48px' }}>HISTORY OF AI</h1>
          <h2
            style={{
              fontWeight: '700',
              fontSize: '16px',
              textAlign: 'justify',
              lineHeight: '24px',
            }}
          >
            Walk through the timeline of AI, starting from the Turing Test and
            early computing to the modern era of machine learning and neural
            networks.
          </h2>
        </div>
      </motion.section>

      <motion.section
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        style={{
          padding: '4rem 0',
          backgroundColor: '#0c0c20',
        }}
      >
        <div
          style={{
            margin: '0 auto',
            padding: '0 20px',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: '2rem',
            
          }}
        >
          <div>
            <p style={{ color: '#d1d5db', fontSize: '20px' }}>
              The journey of artificial intelligence began in the mid-20th
              century. Pioneers like Alan Turing laid the groundwork. They
              imagined machines capable of thinking and learning. In the 1950s,
              researchers created simple algorithms that mimicked human
              reasoning. These early systems sparked excitement but faced
              limitations due to insufficient computing power. The real turning
              point came with advancements in machine learning and neural
              networks in the 21st century. Suddenly, computers began mastering
              complex tasks. Fast forward to the 1980s, when AI experienced a
              resurgence with expert systems. These programs could solve
              specific problems by emulating human expertise. Today, AI
              technology permeates our daily lives—from virtual assistants to
              self-driving cars—transforming how we interact with technology and
              each other. The evolution continues as innovators explore new
              frontiers, pushing boundaries further than ever before.
            </p>
          </div>
          <img
            src={HistoryImage2}
            alt='AI'
            style={{
              borderRadius: '20px',
              width: '100%',
              height: 'auto',
              transition: 'transform 0.3s ease',
            }}
          />
        </div>
      </motion.section>

      <Footer />
    </div>
  )
}

export default History
