import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '../Assests/final_logo_AI museum.png';

const Header = () => {
  const location = useLocation();

  return (
    <Navbar
      expand="md"
      fixed="top"
      className="bg-white shadow-lg"
      style={{
        backdropFilter: "blur(10px)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        transition: "all 0.3s ease-in-out",
      }}
    >
      <Container>
        {/* Logo Section */}
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img
            src={Logo}
            alt="Museum Logo"
            className="img-fluid"
            style={{
              maxWidth: "120px",
              height: "auto",
              objectFit: "cover",
              borderRadius: "10px",
              transition: "transform 0.3s ease-in-out",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          />
        </Navbar.Brand>

        {/* Toggle Button for Mobile */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/* Navbar Links */} 
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto align-items-center">
            {[
              { path: "/", label: "Home" },
              // { path: "/events", label: "Events" },
              // { path: "/training", label: "Training" },
              { path: "/about-us", label: "About Us" },
            ].map(({ path, label}) => (
              <NavLink
                key={path}
                to={path}
                className={`nav-link ${
                  location.pathname === path ? "active" : ""
                }`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: location.pathname === path ? "#4b0082" : "#555",
                  fontWeight: location.pathname === path ? "600" : "500",
                  padding: "10px 20px",
                  transition: "color 0.3s ease, transform 0.3s ease",
                  borderBottom:
                    location.pathname === path ? "2px solid #4b0082" : "none",
                }}
                onMouseEnter={(e) => {
                  e.target.style.color = "#4b0082";
                  e.target.style.transform = "scale(1.1)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = location.pathname === path ? "#4b0082" : "#555";
                  e.target.style.transform = "scale(1)";
                }}
              >
                {/* <span style={{ fontSize: "1.2rem" }}>{icon}</span> */}
                {label}
              </NavLink>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
