import React, { useState, useEffect } from 'react';
import Header from './Header'; // Adjust the path if necessary
import WorldMap from '../Assests/icons Ai/AiWorldwideEvent/Map.jpeg';
import AiWorldwideText from '../Assests/icons Ai/AiWorldwideEvent/AiWorldwideText.svg';
import Handimg from '../Assests/icons Ai/AiWorldwideEvent/Handimg.svg';
import Card from 'react-bootstrap/Card';
import Footer from './Footer'; // Adjust the path if necessary

function AiWorldwideEvent() {

    // this function code used for the mobile respon when the devices with get under the 1354 this sixe then hand imges is diaplsy non
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Check if the screen width is mobile size
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1354);
        };

        // Add resize listener
        handleResize(); // Check initially
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <section style={{ backgroundColor: '#06071B', color: 'white', padding: '50px 0', position: 'relative' }}>
                {/* Header Component */}
                <Header />
                <div className="container text-center">
                    {/* Event Heading */}
                    <div className="position-relative mb-4 mt-5" style={{ position: 'relative' }}>
                        <img src={AiWorldwideText} alt="Event Title" className="img-fluid mb-4" />
                    </div>
                </div>

                <div className="container-fluid">
                    <div>
                        {/* Hand Image */}
                        {!isMobile && ( // Only render if not mobile
                            <img
                                src={Handimg}
                                alt="Hand Illustration"
                                className="position-absolute"
                                style={{
                                    top: '0',
                                    right: '0',
                                    margin: '0',
                                    width: '400px',
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className="container text-center">
                    {/* World Map Section */}
                    <div
                        className="position-relative mb-5"
                        style={{ borderRadius: '20px', overflow: 'hidden' }}
                    >
                        <img
                            src={WorldMap}
                            alt="World Map"
                            className="img-fluid rounded border border-white mt-5"
                            style={{
                                boxShadow: '0 0 20px rgba(255, 255, 255, 0.5)',
                                borderRadius: '20px',
                            }}
                        />
                    </div>

                    {/* Event Cards Section */}
                    <div className="row g-4">
                        {[{ location: 'Dubai' }, { location: 'Singapore' }, { location: 'India' }, { location: 'Malaysia' }].map(
                            (event, index) => (
                                <div className="col-md-3 col-sm-6" key={index}>
                                    <Card
                                        style={{
                                            width: '100%',
                                            marginTop: '25px',
                                            backgroundColor: '#060512',
                                            border: '2px solid white',
                                            borderRadius: '20px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Card.Body>
                                            <Card.Text style={{ color: '#ffffff' }}>
                                                <p className="card-text">
                                                    <small>
                                                        Date: 12 Aug 2025 <br /> 📍 {event.location}
                                                    </small>
                                                </p>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default AiWorldwideEvent;
