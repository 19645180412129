import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { motion } from "framer-motion";
import Committee from '../Assests/icons Ai/d3fbba2e45be26850789e5604672ca09 1.svg'
import TextImg2 from "../Assests/icons Ai/Advisory Committee.svg";

const AiGovernance = () => {
  return (
    <div className="min-vh-100 bg-dark text-white">
      <Header />
      <div className="container py-5">
        <div className="row gy-4 justify-content-center align-items-center">
          {/* First Image */}
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <motion.div
              className="position-relative"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              style={{
                width: "90%",
                height: "300px",
                marginTop: "60px",
                // boxShadow: "0 8px 20px rgba(0,0,0,0.6)",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <img
                src={Committee}
                alt="Advisory Committee"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "fill",
                  borderRadius: "20px",
                }}
              />
            </motion.div>
          </div>

          {/* Second Image with Transparent Text */}
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <motion.div
              className="position-relative"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              style={{
                width: "90%",
                height: "300px",
                marginTop: "20px",
                // boxShadow: "0 8px 20px rgba(0,0,0,0.6)",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <img
                src={TextImg2}
                alt="Advisory Committee 2"
                style={{
                  width: "90%", // Adjust this value as needed
                  maxWidth: "400px", // Set a maximum width
                  height: "auto", // Let the height adjust automatically
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                }}
              />
            </motion.div>
          </div>
        </div>

        {/* Paragraph Section */}
        <motion.section
          className="mt-4 mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          style={{
            backgroundColor: "#111",
            borderRadius: "10px",
            padding: "20px",
            color: "#fff",
            maxWidth: "auto",
            boxShadow: "0 6px 15px rgba(0,0,0,0.5)",
            borderWidth:'1px',
            borderColor: '#F8F8F8',
            borderStyle: 'solid',
          }}
        >
          <p
            style={{
              fontSize: "18px",
              lineHeight: "1.8",
              textAlign: "justify",
              margin: "0",
              padding: "0",
            }}
          >
            {/* Your paragraph content remains unchanged */}
            The AI & Technology Museum offers a variety of educational programs
            tailored for all ages. These workshops encourage hands-on learning,
            fostering creativity and critical thinking among participants.
            Children can engage in coding classes designed to introduce them to
            the fundamentals of programming. Here, they learn not just how to
            code but why it's essential in today’s digital landscape.
            Interactive robotics sessions allow kids to build their own robots.
            This experience enhances problem-solving skills while igniting a
            passion for engineering and technology. For older students, advanced
            workshops dive into machine learning concepts. They explore
            real-world applications that make AI relevant and exciting.
            Educators also find value in specialized training sessions focused
            on integrating AI technologies into classroom settings. This ensures
            that teaching methods evolve alongside advancements in the field.
            Each program is crafted with an emphasis on collaboration, sparking
            discussions about ethical considerations tied to emerging
            technologies.
            {/* Paragraph continues... */}
          </p>
        </motion.section>
      </div>
      <Footer />
    </div>
  );
};

export default AiGovernance;
