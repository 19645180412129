import React, { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2' // Import SweetAlert2
import Header from './Header'
import Footer from './Footer'

const EnquiryForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    place: '',
    comment: '',
  })

  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const validateForm = () => {
    const newErrors = {}
    if (!formData.name.trim()) newErrors.name = 'Name is required'
    if (!formData.place.trim()) newErrors.place = 'Place is required'
    if (!formData.email.trim()) newErrors.email = 'Email is required'
    if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = 'Invalid email address'
    if (!formData.phone.trim()) newErrors.phone = 'Phone number is required'
    if (!/^\d+$/.test(formData.phone))
      newErrors.phone = 'Phone number should only contain digits'
    if (!formData.comment.trim()) newErrors.comment = 'Message is required'
    return newErrors
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formErrors = validateForm()
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
      return
    }

    // Prepare payload for API call
    const payload = {
      full_name: formData.name,
      email: formData.email,
      contact_number: formData.phone,
      place: formData.place, // Replace this with the actual place if needed
      comment: formData.comment,
    }

    try {
      // Make the API request
      const response = await axios.post(
        'https://backend.aiglobalmuseum.com/eventform/register/',
        payload
      )
      console.log('Enquiry submitted:', response.data)

      // Show success alert
      Swal.fire({
        icon: 'success',
        title: 'Submitted!',
        text: 'Your enquiry has been submitted successfully.',
      })

      setFormData({ name: '', email: '', phone: '', place: '', comment: '' })
      setErrors({})
    } catch (error) {
      console.error('Error submitting enquiry:', error)

      // Show error alert
      Swal.fire({
        icon: 'error',
        title: 'Submission Failed',
        text: 'There was an issue submitting your enquiry. Please try again later.',
      })
    }
  }

  return (
    <div>
      <Header />
      <div className='form-container'>
        <div className='form-card'>
          <h2 className='form-title'>AI Museum Enquiry Form</h2>
          <form onSubmit={handleSubmit}>
            {['name', 'email', 'phone', 'place', 'comment'].map((field) => (
              <div className='form-group' key={field}>
                <label htmlFor={field} className='form-label'>
                  {field.charAt(0).toUpperCase() + field.slice(1)}:
                </label>
                {field === 'comment' ? (
                  <textarea
                    id={field}
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    className={`form-input ${
                      errors[field] ? 'form-input-error' : ''
                    }`}
                  />
                ) : (
                  <input
                    type={field === 'email' ? 'email' : 'text'}
                    id={field}
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    className={`form-input ${
                      errors[field] ? 'form-input-error' : ''
                    }`}
                  />
                )}
                {errors[field] && (
                  <span className='form-error'>{errors[field]}</span>
                )}
              </div>
            ))}
            <button type='submit' className='form-button'>
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer />

      <style jsx>{`
        .form-container {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 100vh;
          background: linear-gradient(to right, #3f1f55, #8a45bb);
          padding: 20px;
        }

        .form-card {
          background: white;
          border-radius: 12px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          padding: 40px;
          max-width: 600px;
          width: 100%;
          animation: fadeIn 0.5s ease-in-out;
          margin-top: 70px;
        }

        .form-title {
          text-align: center;
          color: #3f1f55;
          margin-bottom: 20px;
          font-size: 24px;
          font-weight: bold;
        }

        .form-group {
          margin-bottom: 20px;
        }

        .form-label {
          display: block;
          margin-bottom: 5px;
          font-weight: 600;
          color: #3f1f55;
        }

        .form-input {
          width: 100%;
          padding: 12px;
          font-size: 16px;
          border: 1px solid #ddd;
          border-radius: 6px;
          transition: all 0.3s ease;
          background: #f9f9f9;
        }

        .form-input:focus {
          outline: none;
          border-color: #8a45bb;
          box-shadow: 0 0 8px rgba(138, 69, 187, 0.3);
        }

        .form-input-error {
          border-color: red;
        }

        .form-error {
          color: red;
          font-size: 12px;
          margin-top: 5px;
        }

        .form-button {
          width: 100%;
          padding: 12px;
          font-size: 16px;
          font-weight: bold;
          color: white;
          background-color: #8a45bb;
          border: none;
          border-radius: 6px;
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .form-button:hover {
          background-color: #682e92;
          transform: scale(1.02);
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(-20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  )
}

export default EnquiryForm
