import React from 'react'
import { motion } from 'framer-motion'
import Header from './Header' // Adjust the path if necessary
import Footer from './Footer' // Adjust the path if necessary
import JoinAiEvent from '../Assests/icons Ai/EventViewMore/JoinAiEvent.jpeg'
import MeetOurVisionaries from '../Assests/icons Ai/EventViewMore/MeetOurVisionaries.jpeg'
import BuildtrustCredential from '../Assests/icons Ai/EventViewMore/BuildtrustCredential.jpeg'
import BeApartOfAiRevolution from '../Assests/icons Ai/EventViewMore/Be a part of Ai revolution.jpeg'
import StayInformedStayAhead from '../Assests/icons Ai/EventViewMore/StayInformedStayAhead.jpeg'

function EventViewMore() {
    return (
        <div >
            <section >
            <Header />
                {/*  Why should you join our AI Events*/}
                <motion.section
                    initial={{ opacity: 0, x: -100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8 }}
                    style={{
                        padding: '4rem 0',
                        backgroundColor: '#0c0c20',
                        marginTop:'30px'
                    }}
                >
                    <div
                        style={{
                            margin: '0 auto',
                            padding: '0 20px',
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                            gap: '2rem',
                        }}
                    >

                        <img
                            src={JoinAiEvent}
                            alt='AI for Kids'
                            style={{
                                borderRadius: '10px',
                                width: '80%',
                                height: 'auto',
                                transition: 'transform 0.3s ease',
                            }}
                        />

                        <div>
                            <h1
                                style={{
                                    fontSize: '2rem',
                                    fontWeight: 'bold',
                                    color: 'white',
                                }}
                            >
                                Why should you
                                join our AI Events
                            </h1>
                            <p style={{ color: '#d1d5db', fontSize: '22px' }}>
                                Joining our AI events offers a unique opportunity to stay ahead in the rapidly evolving world of artificial intelligence. You'll gain insights from industry experts, explore cutting-edge innovations, and network with professionals and enthusiasts passionate about AI. Our events are designed to foster learning, collaboration, and inspiration, catering to beginners and seasoned experts alike. Participate in engaging workshops, live demos, and discussions to enhance your skills and understanding. Whether you’re exploring AI applications, seeking career opportunities, or looking to innovate, these events provide the perfect platform to grow and connect in a dynamic, future-focused environment. Don’t miss out!
                            </p>
                        </div>
                    </div>
                </motion.section>
                {/*Meet Our Visionaries*/}
                <motion.section
                    initial={{ opacity: 0, x: -100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8 }}
                    style={{
                        padding: '4rem 0',
                        backgroundColor: '#0c0c20',
                    }}
                >
                    <div
                        style={{
                            margin: '0 auto',
                            padding: '0 20px',
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                            gap: '2rem',
                        }}
                    >
                        <div>
                            <h1
                                style={{
                                    fontSize: '2rem',
                                    fontWeight: 'bold',
                                    color: 'white',
                                }}
                            >
                                Meet Our Visionaries
                            </h1>
                            <p style={{ color: '#d1d5db', fontSize: '22px' }}>
                                Step into the future of artificial intelligence with our visionary speakers, the pioneers shaping tomorrow. These experts bring unparalleled knowledge, groundbreaking insights, and transformative ideas to the forefront. From AI ethics to cutting-edge technologies, they’ll share their journeys, challenges, and solutions that drive innovation. Connect with thought leaders who inspire change, foster collaboration, and push the boundaries of possibility. Whether you're an enthusiast, a professional, or a curious learner, their expertise will leave you inspired and empowered. Don’t miss this chance to learn from the minds redefining AI and shaping the world we live in today and tomorrow!
                            </p>
                        </div>
                        <img
                            src={MeetOurVisionaries}
                            alt='AI for Kids'
                            style={{
                                borderRadius: '10px',
                                width: '80%',
                                height: 'auto',
                                transition: 'transform 0.3s ease',
                            }}
                        />
                    </div>
                </motion.section>

                {/* Build trust & Credential */}
                <motion.section
                    initial={{ opacity: 0, x: -100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8 }}
                    style={{
                        padding: '4rem 0',
                        backgroundColor: '#0c0c20',
                    }}
                >
                    <div
                        style={{
                            margin: '0 auto',
                            padding: '0 20px',
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                            gap: '2rem',
                        }}
                    >

                        <img
                            src={BuildtrustCredential}
                            alt='AI for Kids'
                            style={{
                                borderRadius: '10px',
                                width: '80%',
                                height: 'auto',
                                transition: 'transform 0.3s ease',
                            }}
                        />

                        <div>
                            <h1
                                style={{
                                    fontSize: '2rem',
                                    fontWeight: 'bold',
                                    color: 'white',
                                }}
                            >
                                Build trust & Credential
                            </h1>
                            <p style={{ color: '#d1d5db', fontSize: '22px' }}>
                                Our AI speakers are not just experts—they’re trailblazers with proven track records in the field. Each visionary brings years of hands-on experience, academic excellence, and impactful contributions to the AI industry. They’ve worked with leading organizations, published groundbreaking research, and driven transformative projects worldwide. Their insights are rooted in real-world applications, ensuring you gain practical knowledge alongside visionary ideas.
                                By attending, you'll hear from trusted voices who are shaping global AI standards and driving ethical advancements. These speakers don’t just talk about the future—they’re creating it. Join us and connect with the most credible minds in AI today! </p>
                        </div>
                    </div>
                </motion.section>

                {/* Be a part of Ai revolution */}
                <motion.section
                    initial={{ opacity: 0, x: -100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8 }}
                    style={{
                        padding: '4rem 0',
                        backgroundColor: '#0c0c20',
                    }}
                >
                    <div
                        style={{
                            margin: '0 auto',
                            padding: '0 20px',
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                            gap: '2rem',
                        }}
                    >
                        <div>
                            <h1
                                style={{
                                    fontSize: '2rem',
                                    fontWeight: 'bold',
                                    color: 'white',
                                }}
                            >
                                Be a part of Ai revolution
                            </h1>
                            <p style={{ color: '#d1d5db', fontSize: '22px' }}>
                                The AI revolution is here, and it’s transforming industries, redefining innovation, and shaping the future. Join us to become an active participant in this exciting journey. Gain cutting-edge insights from industry pioneers, engage in hands-on workshops, and explore groundbreaking AI solutions that address real-world challenges.
                                Whether you're a professional looking to innovate, a student eager to learn, or an enthusiast passionate about technology, this is your chance to contribute to the next wave of technological evolution. Don’t just witness the future—help build it. Be a part of the AI revolution and make your mark on tomorrow! </p>
                        </div>
                        <img
                            src={BeApartOfAiRevolution}
                            alt='AI for Kids'
                            style={{
                                borderRadius: '10px',
                                width: '80%',
                                height: 'auto',
                                transition: 'transform 0.3s ease',
                            }}
                        />
                    </div>
                </motion.section>
                {/*Stay Informed, Stay Ahead*/}
                <motion.section
                    initial={{ opacity: 0, x: -100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8 }}
                    style={{
                        padding: '4rem 0',
                        backgroundColor: '#0c0c20',
                    }}
                >
                    <div
                        style={{
                            margin: '0 auto',
                            padding: '0 20px',
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                            gap: '2rem',
                        }}
                    >

                        <img
                            src={StayInformedStayAhead}
                            alt='AI for Kids'
                            style={{
                                borderRadius: '10px',
                                width: '80%',
                                height: 'auto',
                                transition: 'transform 0.3s ease',
                            }}
                        />

                        <div>
                            <h1
                                style={{
                                    fontSize: '2rem',
                                    fontWeight: 'bold',
                                    color: 'white',
                                }}
                            >
                                Stay Informed, Stay Ahead
                            </h1>
                            <p style={{ color: '#d1d5db', fontSize: '22px' }}>
                                In the fast-paced world of AI, staying informed is the key to success. Our platform ensures you’re always up to date with the latest advancements, trends, and breakthroughs in artificial intelligence. From expert talks and insightful workshops to real-world case studies, we provide you with valuable knowledge to empower your journey.
                                Stay connected with a community of innovators, receive updates on emerging technologies, and discover how AI is reshaping industries worldwide. Don’t let the future pass you by—stay informed, stay engaged, and stay ahead in the AI revolution. Let’s grow and innovate together!  </p>
                        </div>
                    </div>
                </motion.section>
                <Footer />
            </section>

        </div>
    )
}

export default EventViewMore
