import React from 'react'
import Container from 'react-bootstrap/Container';
import Header from './Header'; // Adjust the path if necessary
import { Card } from 'react-bootstrap';

import EventHightlights1 from '../Assests/icons Ai/EventHightlight/EventHightlights1.svg'
import AIMuseum from '../Assests/icons Ai/EventHightlight/AIMuseum.svg'
import AiMusume from '../Assests/icons Ai/EventHightlight/AiMusume.mp4'
import Footer from './Footer'
function EventHighlights() {
    const cards = [
        { id: 1, image: AIMuseum },
        { id: 2, image: AIMuseum },
        { id: 3, image: AIMuseum },
        { id: 4, image: AIMuseum },
        { id: 5, image: AIMuseum },
        { id: 6, image: AIMuseum },
    ];
    return (
        <div>
            <Header />
            <section style={{ backgroundColor: '#06071B' }}>
                <div className="position-relative vh-100 w-100 mt-4">
                    <img
                        src={EventHightlights1}
                        alt="Event Highlights"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            top: '25%', // Vertically center the text
                            left: '10%', // Adjust to position the text towards the left
                            transform: 'translateY(-50%)', // Only translate vertically
                            color: 'white',
                            textAlign: 'left',
                            fontSize: '3rem',
                            fontWeight: 'bold',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)'
                        }}
                    >
                        Event Highlights
                    </div>
                </div>

                <div
                    className="container py-5 mt-5"
                    style={{
                        border: "2px solid white", // Add a 2px solid white border
                        background: "linear-gradient(135deg, #06071B, #22144A)", // Add the gradient background
                        borderRadius: "10px", // Optional: Add rounded corners for the container
                        padding: "20px", // Inner padding
                    }}
                >
                    <div>
                        <h1 style={{ color: "white", marginBottom: "20px" }}>
                            Photos from last event
                        </h1>
                    </div>
                    <div className="row">
                        {cards.map((card) => (
                            <div key={card.id} className="col-lg-4 col-md-6 mb-4" style={{ textAlign: 'center',alignItems:'center',justifyContent:'center' }}>
                                <div
                                    className="card border-0 shadow"
                                    style={{
                                        background: 'linear-gradient(to bottom, #06071B, #22144A)', // Apply the linear gradient background
                                        borderRadius: '20px', // Optional: add rounded corners to the card
                                    }}
                                >
                                    <img
                                        src={card.image}
                                        className="card-img-top"
                                        alt={`Card ${card.id}`}
                                        style={{
                                            objectFit: 'cover', // Ensure the image covers the card without distortion
                                            borderRadius: '20px 20px 0 0', // Optional: ensure the top corners of the image are rounded
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>

                    <div>
                        <h1 style={{ color: "white", marginBottom: "20px" }}>
                            Video from last event
                        </h1>
                    </div>
                    <div>
                        <video
                            className='object-fit-cover w-100 h-100'
                            autoPlay
                            muted
                            loop
                            style={{ paddingTop: '50px' }} // Add padding above the video
                        >
                            <source src={AiMusume} type='video/mp4' />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </section>
            <Footer />


        </div>
    )
}

export default EventHighlights
