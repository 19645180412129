import React from 'react'
import Header from './Header' // Replace with your actual Header component
import Footer from './Footer' // Replace with your actual Footer component
import backgroundImg from '../Assests/icons Ai/retro-futuristic-space-travel 1.svg'
import { motion } from 'framer-motion'
import AiArtVideo from '../Assests/AI videos/freepik__a-futuristic-laboratory-scene-featuring-a-glowing-__44618.mp4'
 
const AiArt = () => {
  return (
    <div
      style={{
        fontFamily: 'Arial, sans-serif',
        color: '#fff',
        backgroundColor: '#000',
      }}
    >
      {/* Header */}
      <Header />

      {/* Main Section */}
      <main
        style={{
          maxWidth: '1200px',
          margin: '0 auto',
          textAlign: 'center',
          padding: '20px',
        }}
      >
        {/* Hero Section */}
        <motion.section
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          style={{
            position: 'relative',
            height: '100vh',
            textAlign: 'center',
            overflow: 'hidden',
          }}
        >
          {/* Background Image */}
          {/* <img
            src={backgroundImg}
            alt='aiart'
            style={{
              position: 'absolute',
              top: '0',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          /> */}
          <video
            className='object-fit-cover w-100 h-100'
            autoPlay
            muted
            loop
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '110%',
              height: '100%',
              objectFit: 'cover',
              zIndex: 1,
            }}
          >
            <source src={AiArtVideo} type='video/mp4' />
            Your browser does not support the video tag.
          </video>

          {/* Semi-Transparent Overlay */}
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            }}
          ></div>

          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background:
                'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))',
              zIndex: 2,
            }}
          ></div>

          {/* Text Content */}
          <div
            style={{
              position: 'absolute',
              top: '50%', // Vertically center
              left: '50%', // Horizontally center
              transform: 'translate(-50%, -50%)', // Adjust position
              color: 'white',
              textAlign: 'center',
              zIndex: 3,
            }}
          >
            <h1 style={{ fontWeight: '900', fontSize: '48px' }}>AI AND ART</h1>

            <h2
              style={{
                fontWeight: '700',
                fontSize: '16px',
                textAlign: 'justify',
                lineHeight: '24px',
              }}
            >
              Discover how AI collaborates with artists to create music,
              paintings, and digital masterpieces.
            </h2>
          </div>
        </motion.section>

        {/* Description Section */}
        <section
          style={{
            backgroundColor: '#111',
            borderRadius: '10px',
            padding: '30px',
            margin: '20px auto',
            color: '#fff',
            borderWidth:'1px',
            borderColor: '#F8F8F8',
            borderStyle: 'solid',
          }}
        >
          <p style={{ fontSize: '1rem', lineHeight: '1.8' }}>
            An AI art gallery is a fusion of creativity and technology,
            showcasing artworks generated or enhanced by artificial
            intelligence. It celebrates innovation by blending algorithms with
            artistic vision, producing unique pieces ranging from abstract
            designs to hyper-realistic portraits. These galleries often explore
            themes like surreal landscapes, cultural interpretations, and
            dynamic, data-driven art.
          </p>
          <p style={{ fontSize: '1rem', lineHeight: '1.8', marginTop: '15px' }}>
            By pushing the boundaries of traditional artistry, AI art redefines
            the creative process, offering fresh perspectives and challenging
            conventions. Visitors experience a transformative journey,
            witnessing the synergy of machine precision and human creativity. AI
            art galleries embody the future of art, where imagination meets
            cutting-edge technology.
          </p>
        </section>
      </main>

      {/* Footer */}
      <footer
        style={{ backgroundColor: '#000', color: '#fff', padding: '20px' }}
      >
        <div style={{ borderTop: '1px solid #333', paddingTop: '20px' }}>
          <Footer />
        </div>
      </footer>
    </div>
  )
}

export default AiArt
