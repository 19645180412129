import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { motion } from "framer-motion";
import EthicImage from '../Assests/icons Ai/583f7520679751ca606a246aa4e4cb2e 1.svg'
import TextImg3 from "../Assests/icons Ai/Great Minds & Institutions Shaping AI.svg";

const SmartMinds = () => {
  return (
    <div className="min-vh-100 bg-dark text-white">
      <Header />
      <div className="container py-5">
        <div className="row gy-4 justify-content-center align-items-center">
          {/* First Image */}
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <motion.div
              className="position-relative"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              style={{
                width: "90%",
                height: "300px",
                marginTop: "60px",
                // boxShadow: "0 8px 20px rgba(0,0,0,0.6)",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <img
                src={EthicImage}
                alt="Great Minds 1"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </motion.div>
          </div>

          {/* Second Image with Transparent Text */}
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <motion.div
              className="position-relative"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              style={{
                width: "90%",
                height: "300px",
                marginTop: "20px",
                // boxShadow: "0 8px 20px rgba(0,0,0,0.6)",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <img
                src={TextImg3}
                alt="Great Minds"
                style={{
                  width: "auto",
                  height: "200px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                }}
              />
            </motion.div>
          </div>
        </div>

        {/* Paragraph Section */}
        <motion.section
          className="mt-4 mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          style={{
            backgroundColor: "#111",
            borderRadius: "10px",
            padding: "20px",
            color: "#fff",
            maxWidth: "auto",
            boxShadow: "0 6px 15px rgba(0,0,0,0.5)",
            borderWidth:'1px',
            borderColor: '#F8F8F8',
            borderStyle: 'solid',
          }}
        >
          <p
            style={{
              fontSize: "18px",
              lineHeight: "1.8",
              textAlign: "justify",
              margin: "0",
              padding: "0",
            }}
          >
            {/* Your paragraph content remains unchanged */}
            Humans are organic beings with emotions, creativity, and adaptability, thriving on experiences and learning through context. They possess empathy, moral reasoning, and the ability to innovate uniquely. On the other hand, AI is a synthetic creation, designed to process vast data, execute tasks with precision, and learn patterns through algorithms. While AI excels in efficiency, speed, and scalability, it lacks true consciousness, emotions, and ethical intuition. Humans make subjective decisions, influenced by culture and personal experience, whereas AI operates on logic and predefined objectives. Together, they complement each other, with humans guiding AI's purpose and AI amplifying human potential.
            {/* Paragraph continues... */}
          </p>
        </motion.section>
      </div>
      <Footer />
    </div>
  );
};

export default SmartMinds;
