import React, { useState } from 'react';
import { Card, Button, Modal, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import card1 from '../Assests/ai mesume new vectors/cartoon-ai-robot-scene (1) 1.svg';
import card2 from '../Assests/ai mesume new vectors/medium-shot-girl-robot 2.svg';
import card3 from '../Assests/ai mesume new vectors/man-hanging-out-with-robot (1) 2.svg';
import card4 from '../Assests/ai mesume new vectors/view-robot-human-businessperson 2.svg';

import modalImg1 from '../Assests/ai mesume new vectors/cartoon-ai-robot-scene 3.svg';
import modalImg2 from '../Assests/ai mesume new vectors/medium-shot-girl-robot 3.svg';
import modalImg3 from '../Assests/ai mesume new vectors/man-hanging-out-with-robot 2.svg';
import modalImg4 from '../Assests/ai mesume new vectors/view-robot-human-businessperson 3.svg';

// WorkshopCard Component with Modal functionality
const WorkshopCard = ({ title, ageRange, imageSrc, content, modalImageSrc }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <Card
        className="h-100 shadow-sm"
        style={{
          width: "100%",
          borderRadius: "16px",
          overflow: "hidden",
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          border: "none",
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.transform = "scale(1.05)";
          e.currentTarget.style.boxShadow = "0 8px 20px rgba(0, 0, 0, 0.2)";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform = "scale(1)";
          e.currentTarget.style.boxShadow = "0 4px 12px rgba(0, 0, 0, 0.1)";
        }}
      >
        <Card.Img
          variant="top"
          src={imageSrc}
          style={{
            height: "200px",
            objectFit: "cover",
          }}
        />
        <Card.Body>
          <Card.Title
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            {title}
          </Card.Title>
          <Card.Text
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: "16px",
              color: "#5f5f5f",
            }}
          >
            {ageRange}
          </Card.Text>
          <Button
            variant="primary"
            onClick={handleShow}
            style={{
              background: "linear-gradient(90deg, #6a11cb, #2575fc)",
              border: "none",
              padding: "8px 16px",
              borderRadius: "8px",
              transition: "background 0.3s ease, transform 0.2s ease",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          >
            Learn More
          </Button>
        </Card.Body>
      </Card>

      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        centered
        style={{
          borderRadius: "16px",
          backgroundColor: "#1e2a4a", // Dark blue background
          color: "#ffffff", // Light text color
          padding: "20px",
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)", // Subtle shadow for depth
          border: "2px solid #ffffff", // White border to match shared design
        }}
      >
        {/* Body */}
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "left", // Align content to the left
            gap: "16px",
            padding: "24px",
            fontFamily: "Poppins, sans-serif",
            lineHeight: "1.8",
          }}
        >
          {/* Image Section */}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: "16px",
            }}
          >
            <img
              src={modalImageSrc}
              alt={title}
              style={{
                maxWidth: "50%", // Adjust to match the layout proportions
                height: "auto",
                borderRadius: "12px", // Rounded corners
                objectFit: "contain",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", // Subtle shadow
              }}
            />
          </div>

          {/* Content Section */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "12px", // Space between content sections
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px", // Space between title and text
              }}
            >
              <h2
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#ffffff",
                }}
              >
                {title}
              </h2>
              <p
                style={{
                  fontSize: "1rem",
                  color: "black", 
                  lineHeight: "1.6",
                }}
              >
                {content}
              </p>
            </div>
          </div>
        </Modal.Body>

        {/* Footer */}
        <Modal.Footer
          style={{
            justifyContent: "center",
            borderTop: "none", // Remove border for a cleaner look
            paddingTop: "12px",
          }}
        >
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{
              borderRadius: "8px",
              padding: "10px 20px",
              backgroundColor: "#4CAF50",
              color: "#ffffff",
              fontFamily: "Poppins, sans-serif",
              fontSize: "1rem",
              fontWeight: "500",
              border: "none",
              transition: "all 0.3s ease",
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#43A047")}
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#4CAF50")}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
 
// Main Component
export default function WorkshopCardsWithModals() {
  return (
    <Container fluid className="py-5">
      <h2 className="text-center mb-4">Workshop Options</h2>
      <Row style={{ justifyContent: 'space-between' }}>
        {/* Cards with Modal */}
        <Col lg={6} className="mb-4" style={{gap:'5px'}} >
          <WorkshopCard
            title="AI for Young Explorers"
            ageRange="5 to 10 Years Old"
            imageSrc={card1}
            modalImageSrc={modalImg1}
            content={
              <>
                <h5>Introduction to AI:</h5>
                <ul>
                  <li>What is AI? Explaining with stories and games.</li>
                  <li>Real-world AI examples (e.g., Siri, Alexa, and robots).</li>
                </ul>
                <h5>AI in Everyday Life:</h5>
                <ul>
                  <li>How does AI help us? (Traffic lights, games, cartoons).</li>
                </ul>
                <h5>Hands-On Activity:</h5>
                <ul>
                  <li>Create simple AI-powered art and animations using kid-friendly tools like Scratch or Blockly.</li>
                </ul>
                <h5>Interactive Robotics:</h5>
                <ul>
                  <li>Build and program a simple robot using Lego Mindstorms or similar tools.</li>
                </ul>
              </>
            }
          />
        </Col>
        <Col lg={6} className="mb-4">
          <WorkshopCard
            title="AI Innovators in the Making"
            ageRange="10 to 16 Years Old"
            imageSrc={card2}
            modalImageSrc={modalImg2}
            content={
              <>
                <h5>Advanced AI Concepts:</h5>
                <ul>
                  <li>Machine learning, computer vision, and robotics simplified.</li>
                  <li>Real-world applications (healthcare, games, self-driving cars).</li>
                </ul>
                <h5>Hands-On AI Projects:</h5>
                <ul>
                  <li>Build a chatbot using simple coding tools.</li>
                  <li>Train a basic AI model for image or sound recognition.</li>
                </ul>
                <h5>Ethics in AI:</h5>
                <ul>
                  <li>Understanding responsible AI usage.</li>
                </ul>
                <h5>Showcase:</h5>
                <ul>
                  <li>Present projects to parents and peers at the end of the workshop.</li>
                </ul>
              </>
            }
          />
        </Col>
      </Row>
     
      <Row>
        {/* Cards with Modal */}
        <Col lg={6} className="mb-4">
          <WorkshopCard
            title="Building AI Applications for the Future"
            ageRange="16 to 21 Years Old"
            imageSrc={card3}
            modalImageSrc={modalImg3}
            content={
              <>
                <h5>Foundations of AI:</h5>
                <ul>
                  <li>Machine learning, deep learning, and natural language processing (NLP).</li>
                  <li>Case studies of AI in action (healthcare, finance, environment).</li>
                </ul>
                <h5>AI Development Tools:</h5>
                <ul>
                  <li>Introduction to Python, TensorFlow, and Teachable Machine.</li>
                  <li>How to use cloud platforms like Google AI and Azure.</li>
                </ul>
                <h5>Capstone Project:</h5>
                <ul>
                  <li>Build a working AI application (e.g., sentiment analyzer or object detector).</li>
                </ul>
                <h5>Career Insights:</h5>
                <ul>
                  <li>Career paths in AI and data science.</li>
                </ul>
              </>
            }
          />
        </Col>
        <Col lg={6} className="mb-4">
          <WorkshopCard
            title="AI for Business and Beyond"
            ageRange="Working Professionals"
            imageSrc={card4}
            modalImageSrc={modalImg4}
            content={
              <>
                <h5>AI Fundamentals:</h5>
                <ul>
                  <li>AI in business: streamlining processes, increasing efficiency, and innovation.</li>
                  <li>Understanding machine learning, NLP, and predictive analytics.</li>
                </ul>
                <h5>Hands-On AI for Business:</h5>
                <ul>
                  <li>Use case: Building a recommendation engine</li>
                  <li>AI-powered dashboards for business analytics</li>
                </ul>
                <h5>AI Tools and Platforms:</h5>
                <ul>
                  <li>Exploring tools like Python, Tableau, TensorFlow, and AWS AI services.</li>
                </ul>
                <h5>Strategic AI Implementation:</h5>
                <ul>
                  <li>Building AI strategies for organizational growth.</li>
                  <li>Overcoming challenges in AI adoption.</li>
                </ul>
              </>
            }
          />
        </Col>
      </Row>
    </Container>
  );
}