import logo from './logo.svg'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './Components/Home'
import History from './Components/History'
import AiEveryday from './Components/AiEveryday'
import AiArt from './Components/AiArt'
import EthicsFuture from './Components/EthicsFuture'
import AiGovernance from './Components/AiGovernance'
import SmartMinds from './Components/SmartMinds'
import AboutUs from './Components/AboutUs'
import ScrollToTop from './Components/ScrollTotop'
import Events from './Components/Events'
import Training from './Components/Training'
import EnquiryForm from './Components/EnquiryForm'
import EventViewMore from './Components/EventViewMore'
import Explore from './Components/Explore'
import AiWorldwideEvent from './Components/AiWorldwideEvent'
import EventHighlights from './Components/EventHighlights'


function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />

        <Route path='/history' element={<History />} />
        <Route path='/ai-everyday' element={<AiEveryday />} />
        <Route path='/ai-art' element={<AiArt />} />
        <Route path='/ethics-future' element={<EthicsFuture />} />
        <Route path='/ai-governance' element={<AiGovernance />} />
        <Route path='/smart-minds' element={<SmartMinds />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/events' element={<Events />} />
        <Route path='/training' element={<Training />} />
        <Route path='/EventViewMore' element={<EventViewMore />} />
        <Route path='/enquiry-form' element={<EnquiryForm />} />
        <Route path='/explore' element={<Explore />} />
        <Route path='/AiWorldwideEvent' element={<AiWorldwideEvent />} />
        <Route path='/EventHighlights' element={<EventHighlights />} />
      </Routes>
    </Router>
  )
}

export default App
