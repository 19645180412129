import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { motion } from "framer-motion";
import EthicImage from "../Assests/icons Ai/beb9b248c34ce16aee92a1a185f8c5a1 2.svg";
import TextImg from "../Assests/icons Ai/Ethics and Future of AI.svg";

const EthicsFuture = () => {
  return (
    <div className="min-vh-100 bg-dark text-white">
      <Header />
      <div className="container py-5"       style={{
        background: 'linear-gradient(90deg, #22144A, #06071B)'
      }}>
        <div className="row gy-4 justify-content-center align-items-center">
          {/* First Image */}
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <motion.div
              className="position-relative"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              style={{
                width: "90%",
                height: "300px",
                marginTop: "60px",
                // boxShadow: "0 8px 20px rgba(0,0,0,0.6)",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <img
                src={EthicImage}
                alt="Ethics and AI"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </motion.div>
          </div>

          {/* Second Image with Transparent Text */}
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <motion.div
              className="position-relative"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              style={{
                width: "90%",
                height: "300px",
                marginTop: "20px",
                // boxShadow: "0 8px 20px rgba(0,0,0,0.6)",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <img
                src={TextImg}
                alt="Ethics and Future of AI"
                style={{
                  maxWidth: "90%", // Ensures the image scales within the viewport
                  maxHeight: "200px", // Maintains a consistent height on larger screens
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                }}
              />
            </motion.div>
          </div>
        </div>

        {/* Paragraph Section */}
        <motion.section
          className="mt-4 mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          style={{
            backgroundColor: "#111",
            borderRadius: "10px",
            padding: "20px",
            color: "#fff",
            maxWidth: "auto",
            boxShadow: "0 6px 15px rgba(0,0,0,0.5)",
            borderWidth:'1px',
            borderColor: '#F8F8F8',
            borderStyle: 'solid',
          }}
        >
          <p
            style={{
              fontSize: "18px",
              lineHeight: "1.8",
              textAlign: "justify",
              // margin: "0",
              padding: "0",
            }}
          >
            {/* Your paragraph content remains unchanged */}
            At the AI & Technology Museum, future technologies leap off the
            display shelves. Visitors can explore innovations that push
            boundaries and spark imaginations. From autonomous vehicles to smart
            textiles, each exhibit showcases how technology is reshaping our
            world. Interactive models allow guests to experiment with
            cutting-edge gadgets. Imagine programming a drone or testing out
            advanced robotics in real-time. These hands-on experiences offer
            insight into what tomorrow holds. Augmented reality installations
            bring concepts to life. They immerse participants in environments
            where AI integrates seamlessly with daily activities. Witnessing
            these advancements makes complex ideas accessible and engaging for
            all ages. The museum also features prototypes of upcoming inventions
            from leading tech companies and universities. This glimpse into the
            future not only excites young minds but inspires them to envision
            their roles in this evolving landscape of AI technology. Conclusion:
            Embracing the Future at the AI & Technology Museum Visiting the AI &
            Technology Museum is more than just an outing; it's an opportunity
            to dive into a world where innovation thrives and creativity knows
            no bounds. This museum serves as a hub for AI learning, offering
            children a unique chance to explore advanced technologies that shape
            our future. Every exhibit sparks curiosity and encourages young
            minds to ask questions. The interactive activities engage them on
            multiple levels, turning passive observation into active
            participation. As they immerse themselves in virtual reality
            experiences and hands-on workshops, kids not only learn about
            technology but also develop critical thinking skills. The
            educational programs cater to various age groups, making complex
            concepts accessible and fun. Children can gain insights that may
            inspire their career paths or simply ignite a passion for science
            and engineering. By embracing these learning opportunities at the AI
            Museum, we ensure that future generations are well-equipped with
            knowledge about AI technology. This prepares them not just to
            navigate the digital landscape but also to innovate within it. So
            why wait? Exploring this magnificent space promises both
            enlightenment and enjoyment while laying down the groundwork for
            tomorrow's thinkers, creators, and leaders in AI. Let's encourage
            our children's journey into this fascinating realm of possibilities!
            {/* Paragraph continues... */}
          </p>
        </motion.section>
      </div>
      <Footer />
    </div>
  );
};

export default EthicsFuture;
