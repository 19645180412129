import React from 'react'
import HeroImage from '../Assests/icons Ai/woman-wearing-virtual-reality-simulator 1.svg'
import OverlayImage from '../Assests/icons Ai/robot-hand-finger-pointing-ai-technology-backgrou-2022-09-16-09-23-42-utc 1.svg'
import CenterImage from '../Assests/icons Ai/girl-320262_1920-1.svg'
import AboutUsImage from '../Assests/icons Ai/aboutus.png'
import HistoryAI from '../Assests/icons Ai/311b6a216faf56af11c5f3f4117ae88f 1.svg'
import AiEverydayImage from '../Assests/icons Ai/61f358072c5bba9ab59ebb9bffe1319b 1.svg'
import EthicImage from '../Assests/icons Ai/beb9b248c34ce16aee92a1a185f8c5a1 2.svg'
import Tree from '../Assests/icons Ai/image.png'
import AiCommettee from '../Assests/icons Ai/d3fbba2e45be26850789e5604672ca09 2.svg'
import GreatMinds from '../Assests/icons Ai/583f7520679751ca606a246aa4e4cb2e 2.svg'
import Robot from '../Assests/icons Ai/man-320276.svg'
import HeroVideo from '../Assests/AI videos/freepik__a-woman-wearing-a-virtual-reality-headset-stands-i__27796.mp4'

import { Container, Button, Row, Col, Card } from 'react-bootstrap'
import Header from './Header'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import EnquiryForm from './EnquiryForm'
import WorkshopCards from './WorkshopCards'

import firstcard from '../Assests/icons Ai/Eventcard/firstcard.svg'
import secondcard from '../Assests/icons Ai/Eventcard/secondcard.svg'
import thirdcard from '../Assests/icons Ai/Eventcard/thirdcard.svg'
import fourthcard from '../Assests/icons Ai/Eventcard/fourthcard.svg'
import '../Styles/Home.css'

export default function Home() {
  return (
    <div className="min-vh-100 bg-dark text-white">
      <Header />

      {/* Hero Section */}
      <section className="position-relative vh-100">
        {/* <img
          src={HeroImage}
          alt='AI Museum Hero'
          className='object-fit-cover w-100 h-100'
          style={{ marginTop: '50px' }}
        /> */}
        <video
          className="object-fit-cover w-100 h-100"
          autoPlay
          muted
          loop
          style={{ paddingTop: "50px" }} // Add padding above the video
        >
          <source src={HeroVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <img
          src={OverlayImage} // Replace with the actual path of your new image
          alt="Overlay Image"
          className="overlay-image position-absolute"
          style={{
            top: "200px", // Adjust this value as needed to push the image below the header
            left: "10%",
            transform: "translateX(-50%)",
            zIndex: 2,
            maxWidth: "300px", // Adjust the size of the overlay image as needed
          }}
        />

        {/* Gradient Overlay */}
        <div
          className="position-absolute top-0 start-0 w-100 h-100"
          style={{
            background: "linear-gradient(to right, #4b0082, transparent)",
            opacity: "0.7",
          }}
        />

        {/* Content */}
        <Container
          className="position-absolute top-50 start-50"
          style={{
            transform: "translate(-50%, -50%)", // Centers the container horizontally and vertically
            zIndex: "3",
          }}
        >
          <h1
            className="display-4 fw-bold"
            style={{
              textAlign: "center", // Keeps the text visually centered
              position: "relative", // Allows slight adjustment
              left: "10px", // Moves the text slightly to the right
              fontSize: "80px",
            }}
          >
            <span className="position-relative">
              Welcome to the <br /> AI Museum
            </span>
          </h1>
        </Container>
        <Container
          className="d-flex align-items-center justify-content-center position-absolute top-80 start-50 translate-middle"
          style={{ zIndex: "3" }}
        >
          <Card
            className="bg-dark text-white text-center p-4"
            style={{
              width: "90%",
              height: "250px",
              backgroundColor: "#06071B",
              fontFamily: "Inria Serif, serif", // Font family
              fontWeight: 400, // Weight
              borderRadius: "20px",
              color: "white", // Ensures text is visible on a dark background
            }}
          >
            <Card.Body>
              <Row className="align-items-center ">
                {/* Text Section */}
                <Col xs={12} md={8} className="mb-3">
                  <Card.Text
                    className="lead text-md-left text-center" // Align text based on screen size
                    style={{
                      fontSize: "clamp(16px, 2.5vw, 28px)", // Responsive font size
                      fontFamily: "Inria Serif, serif", // Ensure a fallback font
                      borderRadius: "10px",
                    }}
                  >
                    From its historical roots to its transformative impact on
                    our future, explore how AI shapes technology, society, and
                    culture.
                  </Card.Text>
                </Col>

                {/* Image Section (hidden on mobile) */}
                <Col xs={12} md={4} className="text-center d-none d-md-block">
                  <img
                    src={CenterImage} // Replace with the actual image path
                    alt="Side Image"
                    className="img-fluid rounded" // Ensures responsiveness
                    style={{
                      maxHeight: "200px", // Maintain a max height for balance
                      objectFit: "cover", // Ensures proper cropping without distortion
                      width: "100%", // Full width to adapt to container
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>

            <Card.Footer></Card.Footer>
          </Card>
        </Container>
      </section>

      {/* About Us Section */}
      <section className="bg-black py-5">
        <Container style={{ marginTop: "100px" }}>
          <h2
            className="mb-4 fs-1 fw-bold text-center text-md-start"
            style={{ fontSize: "clamp(24px, 3vw, 32px)" }} // Responsive heading size
          >
            About Us
          </h2>
          <Row className="align-items-center">
            <Col xs={12} md={6} className="mb-4 mb-md-0">
              <p
                className="text-light"
                style={{
                  fontSize: "clamp(16px, 1.8vw, 26px)", // Responsive font size
                  textAlign: "justify",
                }}
              >
                The AI Museum: Where Innovation Meets History. We are dedicated
                to exploring the evolution of artificial intelligence—from
                groundbreaking innovations of the past to cutting-edge
                advancements of today. Our mission is to educate, inspire, and
                connect people with the limitless possibilities of AI.
              </p>
            </Col>
            <Col xs={12} md={6} className="text-center">
              <div className="position-relative">
                <img
                  src={AboutUsImage}
                  alt="AI Innovation"
                  className="rounded object-fit-cover img-fluid"
                  style={{
                    width: "80%",
                    height: "auto",
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Exhibits Section */}
      <section
        style={{
          backgroundColor: "#06071B",
          padding: "2rem 0",
        }}
      >
        <Container>
          <h2
            style={{
              marginBottom: "2rem",
              fontSize: "2.5rem",
              fontWeight: "bold",
              color: "#ffffff",
              textAlign: "center",
              textTransform: "uppercase",
              letterSpacing: "2px",
            }}
          >
            Exhibits
          </h2>
          <Row>
            {[
              {
                title: "History of AI",
                path: "/history",
                image: HistoryAI,
                description:
                  "Walk through the timeline of AI, starting from the Turing Test and early computing to the modern era of machine learning and neural networks.",
              },
              {
                title: "AI in Everyday Life",
                path: "/ai-everyday",
                image: AiEverydayImage,
                description:
                  "See how AI powers the tools and gadgets we use daily—smart assistants, recommendation systems, and more.",
              },
              {
                title: "AI and Art",
                path: "/ai-art",
                image: Tree,
                description:
                  "Discover how AI collaborates with artists to create music, paintings, and digital masterpieces.",
              },
              {
                title: "Ethics & Future of AI",
                path: "/ethics-future",
                image: EthicImage,
                description: "A deep dive into the ethical implications of AI.",
              },
              {
                title:
                  " AI Committee / Knowledge Partners / Advisory Committee",
                path: "/ai-governance",
                image: AiCommettee,
                description:
                  "Understanding AI governance and knowledge structures.",
              },
              {
                title: "Great Minds & Institutions Shaping AI",
                path: "/smart-minds",
                image: GreatMinds,
                description:
                  "A look at intelligence and the future of smart minds.",
              },
            ].map((exhibit, index) => (
              <Col
                key={index}
                sm={6}
                lg={4}
                style={{
                  marginBottom: "2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Link to={exhibit.path} style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      maxWidth: "350px",
                      height: "400px",
                      borderRadius: "16px",
                      overflow: "hidden",
                      background: "radial-gradient(circle, #3F1F55, #8A45BB)",
                      boxShadow: "0 10px 15px rgba(0, 0, 0, 0.2)",
                      cursor: "pointer",
                      transition: "box-shadow 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                      const card = e.currentTarget;
                      const description = card.querySelector(".description");
                      description.style.opacity = 1;
                      description.style.transform = "translateY(0)";
                      card.style.boxShadow = "0 15px 25px rgba(0, 0, 0, 0.3)";
                    }}
                    onMouseLeave={(e) => {
                      const card = e.currentTarget;
                      const description = card.querySelector(".description");
                      description.style.opacity = 0;
                      description.style.transform = "translateY(20px)";
                      card.style.boxShadow = "0 10px 15px rgba(0, 0, 0, 0.2)";
                    }}
                  >
                    {/* Image */}
                    <div
                      style={{
                        width: "100%",
                        height: "60%",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={exhibit.image}
                        alt={exhibit.title}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          transition: "transform 0.3s ease",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.transform = "scale(1.05)")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.transform = "scale(1)")
                        }
                      />
                    </div>

                    {/* Title & Description */}
                    <div
                      style={{
                        padding: "1rem",
                        textAlign: "center",
                        color: "#ffffff",
                        height: "40%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginBottom: "0.5rem",
                        }}
                      >
                        {exhibit.title}
                      </h3>
                      <p
                        className="description"
                        style={{
                          fontSize: "0.9rem",
                          lineHeight: "1.4",
                          color: "white",
                          opacity: 0,
                          transform: "translateY(20px)", // Initial position
                          transition: "opacity 0.3s ease, transform 0.3s ease", // Smooth fade and slide up
                        }}
                      >
                        {exhibit.description}
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* AI Workshop Section */}
      <section
        className="py-12"
        style={{
          background: "linear-gradient(to bottom, #E4DFFF)",
        }}
      >
        <Container>
          <h2
            style={{
              textAlign: "center",
              color: "#fff",
              fontSize: "2.5rem",
              fontWeight: "bold",
              marginBottom: "2rem",
              background: "linear-gradient(90deg, #6a11cb, #2575fc)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Training Workshop
          </h2>
          <Row className="align-items-center">
            {/* Left Content */}
            <Col lg={6} className="mb-5 lg:mb-0">
              <h3
                style={{
                  color: "#fff",
                  fontSize: "2rem",
                  fontWeight: "bold",
                  marginBottom: "1.5rem",
                }}
              >
                Welcome to Our AI Training Workshops
              </h3>
              <p
                style={{
                  color: "#d3d3d3",
                  fontSize: "1.25rem",
                  marginBottom: "1.5rem",
                  lineHeight: "1.6",
                }}
              >
                Dive into the fascinating world of Artificial Intelligence at
                the AI Museum with specially designed workshops for all age
                groups.
              </p>
            </Col>

            {/* Right Cards Section */}
            <Col lg={6}>
              <div
                className="grid grid-cols-1 lg:grid-cols-2 gap-6 relative"
                style={{
                  justifyItems: "center",
                  alignItems: "center",
                }}
              >
                {/* Card 1 */}
                <div className="mb-8 lg:mb-0">
                  <WorkshopCards />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/*  Events section */}
      <section
        id="events-section"
        className="py-5"
        style={{ backgroundColor: "#1a0036" }}
      >
        <div className="container">
          <h2 className="text-white mb-4">Events</h2>
          <div className="row g-4">
            {/* Event Card 1 */}
            <div className="col-md-3">
              <Card
                style={{
                  width: "100%",
                  marginTop: "25px",
                  backgroundColor: "#060512",
                  border: "2px solid white",
                  borderRadius: "20px",
                  overflow: "hidden", // Ensures the image and content respect the card's border radius
                }}
              >
                <div
                  className="card text-white bg-dark border-0"
                  style={{ borderRadius: "20px", overflow: "hidden" }} // Ensures the image is clipped by the border radius
                >
                  <div
                    style={{
                      position: "relative", // For gradient overlay positioning
                    }}
                  >
                    <img
                      src={firstcard} // Replace with actual image
                      className="card-img"
                      alt="Event 1"
                      style={{
                        opacity: 0.8,
                        width: "100%",
                        height: "auto",
                        borderRadius: "inherit", // Matches the parent container's border radius
                      }}
                    />
                    {/* Gradient Overlay */}
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background:
                          "linear-gradient(180deg, #13131300, #131313)",
                        zIndex: 1, // Ensures it overlays the image
                        borderRadius: "inherit", // Matches the parent container's border radius
                      }}
                    ></div>
                  </div>
                  <div
                    className="card-img-overlay d-flex flex-column justify-content-end"
                    style={{
                      textAlign: "center",
                      zIndex: 2, // Ensures content appears on top of the gradient
                    }}
                  >
                    <h1 className="card-title">What's Coming up?</h1>
                  </div>
                </div>
              </Card>

              <Card
                style={{
                  width: "100%",
                  marginTop: "25px",
                  backgroundColor: "#060512",
                  height: "20vh",
                  border: "2px solid white",
                  borderRadius: "20px",
                }}
              >
                <Card.Body>
                  {/* <Card.Title>Card Title</Card.Title> */}

                  <Card.Text style={{ color: "#ffffff" }}>
                    <p className="card-text">
                      <small>
                        Date: 12 Aug 2025 <br /> 📍 Pune, India
                      </small>
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%", // Ensure the container has height if necessary
                      }}
                    >
                      <button
                        className="btn btn-outline-light"
                        style={{ borderRadius: "20px" }}
                      >
                        Learn More
                      </button>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            {/* Event Card 2 */}
            <div className="col-md-3">
              <Card
                style={{
                  width: "100%",
                  marginTop: "25px",
                  backgroundColor: "#060512",
                  border: "2px solid white",
                  borderRadius: "20px",
                  overflow: "hidden", // Ensures the image and content respect the card's border radius
                }}
              >
                <div className="card text-white bg-dark border-0">
                  <div
                    style={{
                      position: "relative", // Ensures correct overlay placement
                    }}
                  >
                    <img
                      src={secondcard} // Replace with actual image
                      className="card-img"
                      alt="Event 2"
                      style={{ opacity: 0.8 }}
                    />
                    {/* Gradient Overlay */}
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background:
                          "linear-gradient(180deg, #13131300, #131313)",
                        zIndex: 1, // Ensures it overlays the image
                      }}
                    ></div>
                  </div>
                  <div
                    className="card-img-overlay d-flex flex-column justify-content-end"
                    style={{
                      textAlign: "center",
                      zIndex: 2, // Ensures content appears above the gradient
                    }}
                  >
                    <h1 className="card-title">Events Highlights</h1>
                  </div>
                </div>
              </Card>

              <Card
                style={{
                  width: "100%",
                  marginTop: "25px",
                  backgroundColor: "#060512",
                  height: "20vh",
                  border: "2px solid white",
                  borderRadius: "20px",
                }}
              >
                <Card.Body>
                  {/* <Card.Title>Card Title</Card.Title> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%", // Ensure the container has height if necessary
                    }}
                  >
                    <button
                      className="btn btn-outline-light"
                      style={{ borderRadius: "20px" }}
                    >
                      <a
                        href="/EventHighlights"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        View
                      </a>
                    </button>
                  </div>

                  <Card.Text style={{ color: "#ffffff" }}></Card.Text>
                </Card.Body>
              </Card>
            </div>

            {/* Event Card 3 */}
            <div className="col-md-3">
              <Card
                style={{
                  width: "100%",
                  marginTop: "25px",
                  backgroundColor: "#060512",
                  border: "2px solid white",
                  borderRadius: "20px",
                  overflow: "hidden", // Ensures the image and content respect the card's border radius
                }}
              >
                <div className="card text-white bg-dark border-0">
                  <div
                    style={{
                      position: "relative", // Ensures correct overlay placement
                    }}
                  >
                    <img
                      src={thirdcard} // Replace with actual image
                      className="card-img"
                      alt="Event 3"
                      style={{ opacity: 0.8 }}
                    />
                    {/* Gradient Overlay */}
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background:
                          "linear-gradient(180deg, #13131300, #131313)",
                        zIndex: 1, // Ensures it overlays the image
                      }}
                    ></div>
                  </div>
                  <div
                    className="card-img-overlay d-flex flex-column justify-content-end"
                    style={{
                      textAlign: "center",
                      zIndex: 2, // Ensures content appears above the gradient
                    }}
                  >
                    <h1 className="card-title">Explore What you like...</h1>
                  </div>
                </div>
              </Card>

              <Card
                style={{
                  width: "100%",
                  marginTop: "25px",
                  backgroundColor: "#060512",
                  height: "20vh",
                  border: "2px solid white",
                  borderRadius: "20px",
                }}
              >
                <Card.Body>
                  {/* <Card.Title>Card Title</Card.Title> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%", // Ensure the container has height if necessary
                    }}
                  >
                    <button
                      className="btn btn-outline-light"
                      style={{ borderRadius: "20px" }}
                    >
                      <a
                        href="/explore"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        View
                      </a>
                    </button>
                  </div>

                  <Card.Text style={{ color: "#ffffff" }}></Card.Text>
                </Card.Body>
              </Card>
            </div>

            {/* Event Card 4 */}
            <div className="col-md-3">
              <Card
                style={{
                  width: "100%",
                  marginTop: "25px",
                  backgroundColor: "#060512",
                  border: "2px solid white",
                  borderRadius: "20px",
                  overflow: "hidden", // Ensures the image and content respect the card's border radius
                }}
              >
                <div className="card text-white bg-dark border-0">
                  <div
                    style={{
                      position: "relative", // Ensures correct overlay placement
                    }}
                  >
                    <img
                      src={fourthcard} // Replace with actual image
                      className="card-img"
                      alt="Event 4"
                      style={{ opacity: 0.8 }}
                    />
                    {/* Gradient Overlay */}
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background:
                          "linear-gradient(180deg, #13131300, #131313)",
                        zIndex: 1, // Ensures it overlays the image
                      }}
                    ></div>
                  </div>
                  <div
                    className="card-img-overlay d-flex flex-column justify-content-end"
                    style={{
                      textAlign: "center",
                      zIndex: 2, // Ensures content appears above the gradient
                    }}
                  >
                    <h1 className="card-title">AI Worldwide</h1>
                  </div>
                </div>
              </Card>

              <Card
                style={{
                  width: "100%",
                  marginTop: "25px",
                  backgroundColor: "#060512",
                  height: "20vh",
                  border: "2px solid white",
                  borderRadius: "20px",
                }}
              >
                <Card.Body>
                  {/* <Card.Title>Card Title</Card.Title> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%", // Ensure the container has height if necessary
                    }}
                  >
                    <button
                      className="btn btn-outline-light"
                      style={{ borderRadius: "20px" }}
                    >
                      <a
                        href="/AiWorldwideEvent"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        View
                      </a>
                    </button>
                  </div>

                  <Card.Text style={{ color: "#ffffff" }}></Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>

          {/* View More Button */}
          <div className="text-center mt-4">
            <a href="/EventViewMore" className="btn btn-outline-light">
              View More
            </a>
          </div>
        </div>
      </section>

      {/* Educational Programs Section */}
      <section className="bg-purple-900 py-5">
        <Container className="text-center" style={{ position: "relative" }}>
          <img
            src={Robot} // Replace with the actual path of your new image
            alt="Robot illustration overlay"
            className="position-absolute"
            style={{
              top: "50%",
              left: "1%",
              transform: "translateY(-50%)",
              zIndex: "1",
              maxWidth: "300px",
              height: "auto",
              display: window.innerWidth < 768 ? "none" : "block",
            }}
          />

          <div
            style={{
              // marginLeft: "10px",
              backgroundColor: "#3F1F55",
              borderRadius: "15px",
              height: "250px",
              paddingTop: "20px",
            }}
          >
            <h2 className="mb-3 fs-1 fw-bold">
              Educational Programs And Workshops
            </h2>
            <p
              className="mb-4 mx-auto text-light"
              style={{ maxWidth: "600px" }}
            >
              The AI & Technology Museum Offers A Variety Of Educational
              Programs Tailored For All Ages. These Workshops Encourage Hands-On
              Learning, Fostering Creativity And Critical Thinking Among
              Participants.
            </p>
            <Link to="/enquiry-form">
              <Button
                variant="warning"
                size="lg"
                style={{ fontWeight: "bold" }}
              >
                Register Now
              </Button>
            </Link>
          </div>
        </Container>
      </section>

      <Footer />
    </div>
  );
}
