import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import EventHeading from '../Assests/ai mesume new vectors/Event Category.svg'
import HistoryAI from '../Assests/ai mesume new vectors/explorePage_card1.svg'
import AiEverydayImage from '../Assests/ai mesume new vectors/f796f55857b78e6f1b349ddfe466dcf7 2.svg'
import EthicImage from '../Assests/icons Ai/beb9b248c34ce16aee92a1a185f8c5a1 2.svg'
import Tree from '../Assests/ai mesume new vectors/e168ca7881455a7228a18f1b8e8906ed 2.svg'
import AiCommettee from '../Assests/ai mesume new vectors/d3fbba2e45be26850789e5604672ca09 2.svg'
import { Link } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'


const Explore = () => {
  return (
    <div>
      <Header />
      {/* Exhibits Section */}
      <section
        style={{
          backgroundColor: "#06071B",
          padding: "2rem 0",
        }}
      >
        <Container>
          <div
            style={{
              marginBottom: "2rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop:'70px',
            }}
          >
            <img
              src={EventHeading}
              alt="Event Category"
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </div>
          <Row>
            {[
              {
                title: "Workshop",
                path: "/",
                image: HistoryAI,
                description: " ",
              },
              {
                title: "Conference",
                path: "/",
                image: AiEverydayImage,
                description: " ",
              },
              {
                title: "Meetups",
                path: "/",
                image: Tree,
                description: " ",
              },
              {
                title: "Hackathon",
                path: "/",
                image: EthicImage,
                description: "",
              },
              {
                title: "Exhibitions",
                path: "/",
                image: AiCommettee,
                description: "",
              },
            ].map((exhibit, index) => (
              <Col
                key={index}
                sm={6}
                lg={4}
                style={{
                  marginBottom: "2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Link to={exhibit.path} style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      maxWidth: "350px",
                      height: "350px",
                      borderRadius: "16px",
                      overflow: "hidden",
                      background: "radial-gradient(circle, #3F1F55, #8A45BB)",
                      boxShadow: "0 10px 15px rgba(0, 0, 0, 0.2)",
                      cursor: "pointer",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                      const card = e.currentTarget;
                      const description = card.querySelector(".description");
                      description.style.opacity = 1;
                      description.style.maxHeight = "200px";
                      card.style.transform = "scale(1.05)";
                      card.style.boxShadow = "0 15px 25px rgba(0, 0, 0, 0.3)";
                    }}
                    onMouseLeave={(e) => {
                      const card = e.currentTarget;
                      const description = card.querySelector(".description");
                      description.style.opacity = 0;
                      description.style.maxHeight = "0";
                      card.style.transform = "scale(1)";
                      card.style.boxShadow = "0 10px 15px rgba(0, 0, 0, 0.2)";
                    }}
                  >
                    {/* Image */}
                    <div
                      style={{
                        width: "100%",
                        height: "60%",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={exhibit.image}
                        alt={exhibit.title}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>

                    {/* Title & Description */}
                    <div
                      style={{
                        padding: "1rem",
                        textAlign: "center",
                        color: "#ffffff",
                      }}
                    >
                      <h3
                        style={{
                          fontWeight: "bold",
                          fontSize: "2.2rem",
                          marginBottom: "0.5rem",
                          justifyContent:"center",
                          alignItems:"center"
                        }}
                      >
                        {exhibit.title}
                      </h3>
                      <p
                        className="description"
                        // style={{
                        //   fontSize: "0.9rem",
                        //   lineHeight: "1.4",
                        //   color: "#cccccc",
                        //   opacity: 0,
                        //   maxHeight: 0,
                        //   overflow: "hidden",
                        //   transition: "opacity 0.3s ease, max-height 0.3s ease",
                        // }}
                      >
                        {exhibit.description}
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
}

export default Explore
