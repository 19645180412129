import React from 'react'
import Header from './Header' // Adjust the path if necessary
import Footer from './Footer' // Adjust the path if necessary
import { motion } from 'framer-motion'

import Everyday from '../Assests/icons Ai/61f358072c5bba9ab59ebb9bffe1319b 1-1.svg'
import AiKids from '../Assests/icons Ai/b4ca1b_e0a4b33ff89441f0b42d42466d4bd920~mv2 1.svg'
import Professional from '../Assests/icons Ai/Professionals.jpg'
import Industry from '../Assests/icons Ai/Industry.jpg'
import All from '../Assests/icons Ai/e36052bf43b88754f404ebe2d4a7ddad 1.svg'
import EverydayVideo from '../Assests/AI videos/freepik__a-modern-spacious-living-room-with-large-windows-s__44612.mp4'

const AiEveryday = () => {
  return (
    <div
      style={{
        minHeight: '100vh',
        backgroundColor: '#0a0a1e',
        color: 'white',
        fontFamily: "'Poppins', sans-serif",
      }}
    >
      <Header />

      {/* Hero Section */}
      <motion.section
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        style={{
          position: 'relative',
          height: '100vh',
          textAlign: 'center',
          overflow: 'hidden',
          color: 'white',
        }}
      >
        {/* Background Image */}
        {/* <img
          src={Everyday}
          alt='AI Smart Home'
          style={{
            position: 'absolute',
            top: '0',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        /> */}

        <video
          className='object-fit-cover w-100 h-100'
          autoPlay
          muted
          loop
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 1,
          }}
        >
          <source src={EverydayVideo} type='video/mp4' />
          Your browser does not support the video tag.
        </video>

        {/* Text Content */}
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))',
            zIndex: 2,
          }}
        ></div>

        {/* Text Content */}
        <div
          style={{
            position: 'absolute',
            top: '50%', // Vertically center
            left: '50%', // Horizontally center
            transform: 'translate(-50%, -50%)', // Adjust position
            color: 'white',
            textAlign: 'center',
            zIndex: 3,
          }}
        >
          <h1 style={{ fontWeight: '900', fontSize: '48px' }}>
            AI IN EVERYDAY LIFE
          </h1>
          <h2
            style={{
              fontWeight: '400',
              fontSize: '18px',
              maxWidth: '600px',
              margin: '20px auto',
              lineHeight: '1.6',
              color: '#d1d5db',
              textShadow: '1px 1px 5px rgba(0,0,0,0.8)',
            }}
          >
            Experience the transformative power of artificial intelligence in
            your daily routine.
          </h2>
        </div>
      </motion.section>

      {/* AI For Kids Section */}
      <motion.section
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        style={{
          padding: '4rem 0',
          backgroundColor: '#1a1a3d',
        }}
      >
        <div
          style={{
            margin: '0 auto',
            padding: '0 20px',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: '2rem',
          }}
        >
          <div>
            <h2
              style={{
                fontSize: '2rem',
                fontWeight: 'bold',
                color: '#3b82f6',
              }}
            >
              AI For Kids
            </h2>
            <p style={{ color: '#d1d5db', fontSize: '22px' }}>
              AI, or Artificial Intelligence, is like a smart helper that makes
              machines think and act like humans. It helps devices like phones,
              computers, and robots learn from data and solve problems. For
              example, AI powers virtual assistants like Siri or Alexa,
              recommends your favorite shows on streaming platforms, and even
              helps cars drive themselves! Kids can use AI to learn faster with
              personalized apps, create cool art, or play fun games. It’s like
              teaching machines to be clever. Learning about AI helps kids
              understand how technology works and inspires them to build amazing
              things in the future!
            </p>
          </div>
          <img
            src={AiKids}
            alt='AI for Kids'
            style={{
              borderRadius: '10px',
              width: '80%',
              height: 'auto',
              transition: 'transform 0.3s ease',
            }}
          />
        </div>
      </motion.section>

      {/* AI For Professionals Section */}
      <motion.section
        initial={{ opacity: 0, x: 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.9 }}
        style={{
          padding: '4rem 0',
        }}
      >
        <div
          style={{
            margin: '0 auto',
            padding: '0 20px',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: '2rem',
          }}
        >
          <img
            src={Professional}
            alt='AI for Professionals'
            style={{
              borderRadius: '10px',
              width: '80%',
              height: 'auto',
              transition: 'transform 0.3s ease',
            }}
          />
          <div>
            <h2
              style={{
                fontSize: '2rem',
                fontWeight: 'bold',
                color: '#3b82f6',
              }}
            >
              AI For Professionals
            </h2>
            <p style={{ color: '#d1d5db', fontSize: '22px' }}>
              Artificial Intelligence (AI) is transforming industries by
              automating tasks, enhancing decision-making, and boosting
              efficiency. For professionals, AI serves as a powerful tool to
              analyze data, predict trends, and optimize workflows. In
              healthcare, AI assists in diagnosing diseases; in finance, it
              detects fraud and automates trading. Marketing professionals use
              AI to personalize campaigns, while in design, AI generates ideas
              and speeds up prototyping. Tools like ChatGPT, predictive
              analytics, and machine learning platforms enhance productivity and
              innovation. By integrating AI, professionals can focus on
              strategic, creative, and human-centered tasks, making AI a partner
              in achieving greater outcomes and competitive advantage.
            </p>
          </div>
        </div>
      </motion.section>

      {/* AI For Industries Section */}
      <motion.section
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.9 }}
        style={{
          padding: '4rem 0',
          backgroundColor: '#1a1a3d',
        }}
      >
        <div
          style={{
            margin: '0 auto',
            padding: '0 20px',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: '2rem',
          }}
        >
          <div>
            <h2
              style={{
                fontSize: '2rem',
                fontWeight: 'bold',
                color: '#3b82f6',
              }}
            >
              AI For Industries
            </h2>
            <p style={{ color: '#d1d5db', fontSize: '22px' }}>
              Artificial Intelligence (AI) is transforming industries by
              automating tasks, enhancing decision-making, and boosting
              efficiency. For professionals, AI serves as a powerful tool to
              analyze data, predict trends, and optimize workflows. In
              healthcare, AI assists in diagnosing diseases; in finance, it
              detects fraud and automates trading. Marketing professionals use
              AI to personalize campaigns, while in design, AI generates ideas
              and speeds up prototyping. Tools like ChatGPT, predictive
              analytics, and machine learning platforms enhance productivity and
              innovation. By integrating AI, professionals can focus on
              strategic, creative, and human-centered tasks, making AI a partner
              in achieving greater outcomes and competitive advantage.
            </p>
          </div>
          <img
            src={Industry}
            alt='AI for Industries'
            style={{
              borderRadius: '10px',
              width: '80%',
              height: 'auto',
              transition: 'transform 0.3s ease',
            }}
          />
        </div>
      </motion.section>

      <motion.section
        initial={{ opacity: 0, x: 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.9 }}
        style={{
          padding: '4rem 0',
        }}
      >
        <div
          style={{
            margin: '0 auto',
            padding: '0 20px',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: '2rem',
          }}
        >
          <img
            src={All}
            alt='AI for Professionals'
            style={{
              borderRadius: '10px',
              width: '80%',
              height: 'auto',
              transition: 'transform 0.3s ease',
            }}
          />
          <div>
            <h2
              style={{
                fontSize: '2rem',
                fontWeight: 'bold',
                color: '#3b82f6',
              }}
            >
              AI For ALL
            </h2>
            <p style={{ color: '#d1d5db', fontSize: '22px' }}>
              Artificial Intelligence (AI) is transforming industries by
              automating tasks, enhancing decision-making, and boosting
              efficiency. For professionals, AI serves as a powerful tool to
              analyze data, predict trends, and optimize workflows. In
              healthcare, AI assists in diagnosing diseases; in finance, it
              detects fraud and automates trading. Marketing professionals use
              AI to personalize campaigns, while in design, AI generates ideas
              and speeds up prototyping. Tools like ChatGPT, predictive
              analytics, and machine learning platforms enhance productivity and
              innovation. By integrating AI, professionals can focus on
              strategic, creative, and human-centered tasks, making AI a partner
              in achieving greater outcomes and competitive advantage.
            </p>
          </div>
        </div>
      </motion.section>

      <Footer />
    </div>
  )
}

export default AiEveryday
