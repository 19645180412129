import React from 'react'
import Header from './Header'
import Footer from './Footer'
import EthicImage from '../Assests/icons Ai/young-man-woman-protective-glasses-doing-experiments-robotics-laboratory-robot 1.svg'
import HandImage from '../Assests/icons Ai/robot-hand-finger-pointing-ai-technology-backgrou-2022-09-16-09-23-42-utc 4.svg'
import AboutUsImage from '../Assests/AI videos/freepik__a-futuristic-scene-depicting-a-human-and-a-robot-w__39440.mp4'

const AboutUs = () => {
  return (
    <div className='min-vh-100 bg-dark text-white'>
      <Header />
      <div className='container py-5'>
        <div
          className='d-flex justify-content-center gap-4 align-items-center'
          style={{ flexWrap: 'wrap' }}
        >
          {/* First Image */}
          <div
            className='position-relative'
            style={{ width: '45%', height: '300px', marginTop: '100px' }}
          >
            <img
              src={EthicImage}
              alt='Ethics and AI'
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover', // Ensures the image fits
              }}
            />
          </div>

          {/* Second Image with Transparent Text */}
          <div
            className='position-relative'
            style={{ width: '45%', height: '300px', marginTop: '100px' }}
          >
            {/* <img
              src={EthicImage}
              alt='Ethics and AI'
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover', // Ensures the image fits
              }}
            /> */}

            {/* Transparent Text */}
            <span
              style={{
                position: 'absolute',
                top: '50%', // Adjust vertical position
                left: '50%', // Adjust horizontal position
                transform: 'translate(-50%, -50%)', // Center the text
                backgroundColor: EthicImage,
                fontSize: '2.5rem',
                fontWeight: 'bold',

                textAlign: 'center',
                zIndex: 2,
              }}
            >
              Why Visit Us?
            </span>

            {/* Hand Image */}
            <div
              className='d-flex align-items-center'
              style={{
                position: 'absolute',
                bottom: '20px', // Adjust positioning
                right: '20px',
                overflow: 'hidden', // Ensures content doesn't overflow
              }}
            >
              <img
                src={HandImage}
                alt='Hand Icon'
                style={{
                  height: 'auto', // Keeps aspect ratio intact
                  maxWidth: '100%', // Makes sure the image fits within the container
                }}
              />
            </div>
          </div>

          <section
            style={{
              backgroundColor: '#111',
              borderRadius: '10px',
              padding: '30px',
              margin: '20px auto',
              color: '#fff',
            }}
          >
            <p style={{ fontSize: '1rem', lineHeight: '1.8' }}>
              Whether you're a tech enthusiast, a family seeking an educational
              outing, or simply someone curious about the role of AI in our
              world, our museum offers something truly unique for everyone. Dive
              into immersive experiences with our state-of-the-art virtual
              reality and augmented reality exhibits that bring the capabilities
              of AI to life in ways you've never imagined. Engage in workshops,
              seminars, and interactive sessions led by industry experts in AI,
              robotics, and machine learning, providing an opportunity to learn
              and connect with cutting-edge knowledge. Discover a vision for the
              future as you explore how AI is transforming industries, solving
              real-world problems, and inspiring the next generation of thinkers
              and creators.
            </p>
          </section>

          <div
            style={{
              marginTop: '40px', // Adds space above the video
              marginBottom: '40px', // Adds space below the video
              position: 'relative', // Makes sure the video is placed correctly
              height: 'auto',
              width: '100%',
            }}
          >
            <video
              className='object-fit-cover w-100'
              autoPlay
              muted
              loop
              style={{
                width: '100%',
                height: '600px', // Adjust the height as needed
                objectFit: 'cover', // Ensures the video fits correctly
              }}
            >
              <source src={AboutUsImage} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default AboutUs
